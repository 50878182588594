/* -- Theme Toggle -- */
/* -- Light VS dark theme ----- */
/* --------------------------------- */

/* Light Theme (default) */
body.light {
  background-color: #f7f9fc;
  color: #333;
}

/* Dark Theme */
body.dark {
  background-color: #1d1f21;
  color: #e0e0e0;
}

body.dark {
  background-color: #121212; /* Deep dark background */
  color: #e0e0e0; /* Light gray text for readability */
}

body.dark .dashboard-container {
  background-color: #1f1f1f; /* Dark gray for container */
  color: #e0e0e0;
}

body.dark .dashboard-container h2 {
  color: #ffffff; /* White for headers */
}

body.dark .theme-toggle-button {
  background-color: #333; /* Darker button background */
  color: #e0e0e0;
  border: 1px solid #555; /* Subtle border for visibility */
}

body.dark .jobs-header {
  background-color: #2a2a2a; /* Slightly lighter dark background */
  border-color: #444; /* Darker border for consistency */
}

body.dark #sort-by-dropdown {
  background-color: #2e2e2e; /* Dark background for dropdown */
  color: #e0e0e0;
  border: 1px solid #555; /* Subtle border */
}

body.dark #sort-by-dropdown:hover {
  background-color: #3b3b3b; /* Slightly lighter hover effect */
}

body.dark .panel,
body.dark .metric-panel {
  background-color: #1f1f1f; /* Dark panel background */
  color: #e0e0e0; /* Light text for contrast */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Adjusted shadow */
}

body.dark .progress-tracker {
  background-color: #232323; /* Darker tracker background */
  color: #e0e0e0;
}

body.dark .action-button {
  background-color: #2e2e2e;
  color: #4981fd; /* Keeping the primary color */
  border: 1px solid #4981fd;
}

body.dark .action-button:hover {
  box-shadow: 0 8px 15px rgba(73, 129, 253, 0.4);
}

body.dark .jobs-table th {
  background-color: #2a2a2a; /* Darker header background */
  color: #e0e0e0;
}

body.dark .jobs-table td {
  background-color: #1f1f1f; /* Dark cell background */
  color: #e0e0e0;
}

body.dark .modal-content {
  background-color: #2a2a2a; /* Dark modal background */
  color: #e0e0e0;
}

body.dark .dropzone {
  border-color: #4981fd; /* Retain the accent color */
  background-color: #1f1f1f; /* Dark dropzone background */
}

body.dark .toast {
  background-color: #2a2a2a;
  color: #e0e0e0;
}

body.dark .loading-spinner {
  border-color: rgba(255, 255, 255, 0.2); /* Subtle white outline */
  border-left-color: #4981fd; /* Primary accent color */
}

body.dark .metric-title {
  color: #fff;
}

body.dark .metric-value {
  color: #fff;
}

body.dark .comparison-text {
  color: #e0e0e0;
}

body.dark .button-text {
  color: #fff;
}

body.dark .action-button {
  color: #fff;
}

body.dark .tailor-button {
  color: #fff;
}

/* -- Dashboard Styles -- */
body {
  background-color: #fbfbfb;
}
.dashboard-container {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 24px 0px 24px;
  gap: 16px;
  align-self: stretch;
}

.dashboard-container h2 {
  color: var(--Gray-900, #101828);
  font-family: "Plus Jakarta Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Container for jobs heading and "Add Job" button */
.jobs-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;

  margin-top: 50px; /* Adjust as necessary to space out the sections */
  margin-bottom: 20px; /* To add some spacing below */
  background-color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border: 1px solid #ebeaed;
}

.jobs-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.jobs-table-container {
  margin-top: 20px;
}

#sort-by-dropdown {
  padding: 14px 12px;
  border: 1px solid #d1d5db; /* Light gray border for better visibility */
  border-radius: 8px; /* Smooth rounded corners */
  background-color: #f9fafb; /* Light background for better readability */
  color: #111827; /* Darker text for good contrast */
  font-size: 14px; /* Appropriate font size */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 18px;
}

#sort-by-dropdown:hover {
  background-color: #f3f4f6; /* Slightly darker background on hover */
}

#sort-by-dropdown:focus {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5); /* Blue outline for accessibility */
}

.sort-by-dropdown-item {
  background-color: #ffffff; /* White background for options */
  color: #111827; /* Dark text */
}

.buttons-section {
  display: flex;
  gap: 16px; /* Spacing between buttons */
  margin-top: 20px; /* Adjust as necessary */
}

@media (max-width: 768px) {
  .buttons-section {
    flex-direction: column;
    align-items: center;
  }
}

/* Panels Section */

.action-button {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #fff;
  color: #4981fd; /* Default text color for buttons */
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #4981fd;
}

/* Hover effect for all buttons */
.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(73, 129, 253, 0.4);
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  word-wrap: break-word;
}

.action-icon-container {
  width: 20px;
  height: 20px;
  position: relative;
}

.action-text {
  color: #4981fd;
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-template-rows: repeat(2, 1fr); /* 2 equal rows */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.panel,
.metric-panel {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive Styling for Smaller Screens */
@media (max-width: 768px) {
  .dashboard-grid {
    display: grid;
    grid-template-columns: 1fr; /* Switch to a single-column layout */
    grid-template-rows: auto; /* Adjust rows automatically */
    gap: 20px;
    width: 100%;
  }

  .progress-section {
    grid-column: 1 / 2; /* Occupy the entire width in smaller screens */
    grid-row: auto; /* Adjust automatically based on content */
  }

  .metrics-section,
  .progress-section {
    width: 100%; /* Ensure full width for better visibility */
  }
}

/* Additional Mobile Optimization for Panels */
@media (max-width: 480px) {
  .dashboard-grid {
    display: flex;
    flex-direction: column; /* Stack the panels vertically */
    gap: 10px; /* Smaller gaps for better fit on very small screens */
    width: 100%;
  }

  .panel,
  .metric-panel {
    padding: 16px; /* Reduce padding for better fit on small screens */
    width: 100%; /* Ensure the panel takes the full width */
  }

  .metric-value {
    font-size: 24px; /* Reduce font size to make numbers fit better */
  }

  .metric-title {
    font-size: 16px; /* Reduce font size to avoid text overflow */
  }

  .progress-tracker h2 {
    font-size: 20px; /* Adjust text size for the progress tracker */
  }
}

/* Styles for Other Components */
.metric-header {
  display: flex;
  align-items: center;
  gap: 14px;
}

.icon-container {
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, #16c2fc 0%, #7d3fff 100%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metric-title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  color: #585d67;
  font-size: 18px;
}

.metric-value {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  color: #0e1b2f;
  font-size: 32px;
  margin-top: 14px;
}

.metric-change {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #12b76a;
}

.percentage {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.comparison-text {
  color: #475466;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.progress-section {
  grid-row: 1 / 3; /* Span both rows */
  grid-column: 3 / 4; /* Take up the third column */
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-tracker {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.progress-tracker h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  color: #0e1b2f;
  font-size: 24px;
  position: relative;
  bottom: 20px;
}

/* -- Jobs Table -- */
.jobs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 10%;
}

/*
  .input-field {
    width: 100%;
    padding: 5px;
  }*/

/* -- Toast component -- */

/* General styles for both job checkboxes and the select-all checkbox */
.job-checkbox,
.select-all-checkbox {
  appearance: none; /* Remove default checkbox styling */
  width: 18px;
  height: 18px;
  border: 2px solid #00a488; /* Green border for the checkbox */
  border-radius: 4px; /* Rounded corners */
  outline: none;
  cursor: pointer;
  position: relative;
  vertical-align: middle; /* Align with middle of the row */
}

/* Styles for when the checkbox is checked */
.job-checkbox:checked,
.select-all-checkbox:checked {
  background-color: #00a488; /* Green background when checked */
  border-color: #00a488; /* Keep border color consistent */
}

/* Custom checkmark for checked state */
.job-checkbox:checked::after,
.select-all-checkbox:checked::after {
  content: "✔";
  color: #ffffff; /* White checkmark color */
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast {
  position: absolute;
  background-color: #1d3557;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
}

.toast-message {
  margin-right: 10px;
}

.toast-close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

/* -- Spinner -- */
.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

.jobs-table {
  width: 100%;
  border-collapse: collapse;
}
/*
  .input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
    color: #333;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #457b9d;
    box-shadow: 0 0 5px rgba(69, 123, 157, 0.5);
  }*/

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #457b9d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.modal-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px; /* Adjust the gap to control spacing between buttons */
  margin-top: 16px; /* Add space between the text and buttons */
}

.modal-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-button {
  background-color: #f56a6a; /* Example: red color for 'Yes' button */
  color: white;
}

.modal-cancel-button {
  background-color: #c21919; /* Example: gray color for 'No' button */
  color: white;
}

.confirm-button:disabled {
  background-color: #e6a8a8;
  cursor: not-allowed;
}

.select-button {
  background-color: #457b9d;
  color: white;
}

.bulk-button {
  background-color: #1d3557;
  color: white;
}

.delete-button {
  background-color: #e63946;
  color: white;
}

.confirmation-input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bulk-actions-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 5px 0;
  width: 150px;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.delete-item {
  color: red;
}

.checkbox-cell {
  width: 40px; /* Consistent width for the checkbox column */
}

.checkbox-cell.hidden {
  visibility: hidden; /* Maintain space but hide visually */
}

.checkbox-cell.visible {
  visibility: visible;
}

.jobs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.jobs-table th {
  color: var(--Gray-600, #475467);
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-align: left;
  margin: 5px;

  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  gap: 12px;
  height: 44px;
  padding: 12px 24px;
  position: relative;
}

.jobs-table td {
  height: 72px;
  padding: 16px 24px;
  color: #0e1b2f;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #fff;
}

.input-field {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
}
.tailor-button {
  width: 132px;
  height: 33px;
  padding: 2px 12px;
  mix-blend-mode: multiply;
  background: #007bff;
  border-radius: 16px;
  border: 1px solid #c3c3c3;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  cursor: pointer;
}

.tailor-button:hover {
  background: rgb(34, 85, 195);
}

.tailor-button:focus {
  border: 1px solid #000;
}

.tailor-icon-container {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.tailor-icon {
  width: 100%;
  height: 100%;
}

.tailor-text {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
}

.download-button {
  width: 104px;
  height: 28px;
  padding: 2px 12px;
  mix-blend-mode: multiply;
  background: #00a488;
  border-radius: 16px;
  border: 1px solid;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  text-decoration: none;
}

.download-button:hover {
  background-color: rgb(3, 118, 99);
}

.download-icon-container {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.download-icon {
  width: 100%;
  height: 100%;
}

.download-text {
  text-align: center;
  color: white;
  font-size: 12px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
}

.save-job-button {
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-job-button:disabled {
  background-color: #cccccc; /* Gray out button */
  cursor: not-allowed; /* Show "not allowed" cursor */
}

.delete-job-button {
  background-color: #e63946;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.2s ease-in;
}

/* Loading Spinner Styles */
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Fill parent container */
  width: 100%; /* Optional: Adjust as per container size */
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.top-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-job-button {
  padding: 12px 16px;
  background: linear-gradient(247deg, #16c2fc 0%, #7d3fff 100%);
  border-radius: 8px;
  border: 1px solid #7d3fff;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: auto; /* Make width dependent on the content */
  max-width: 300px; /* Optional: Limit the maximum width */
  margin-right: 35px;
}

.add-job-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(125, 63, 255, 0.4);
}

.add-job-icon {
  width: 20px;
  height: 20px;
  position: relative;
}

.job-link-button {
  display: flex;
  height: 28px;
  padding: 2px 12px;
  align-items: center;
  gap: 4px;

  border-radius: 16px;
  background: rgba(73, 129, 253, 0.1);
  mix-blend-mode: multiply;
  text-decoration: none;
  color: #497dfd;
  font-weight: 500;
}

.job-link-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.link-icon {
  width: 16px;
  height: 16px;
}

.edit-job-button {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: transparent; /* Modify based on your desired background color */
  border: none; /* No default button border */
  cursor: pointer;
}

.edit-job-button:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Optional hover effect */
}

.delete-job-button {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: transparent; /* Modify based on your desired background color */
  border: none; /* No default button border */
  cursor: pointer;
}

.delete-job-button:hover {
  background-color: rgba(255, 0, 0, 0.1); /* Optional hover effect */
}

.action-buttons-container {
  display: flex;
  gap: 10px; /* Space between the buttons */
  justify-content: flex-start; /* Align buttons horizontally from the start */
  align-items: center; /* Make sure buttons are aligned vertically in the center */
}

.bulk-actions-dropdown.active {
  display: block;
}

.bulk-actions-dropdown {
  display: none; /* Hide dropdown when not active */
}

.styled-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #1d72b8; /* Color of the checkbox when checked */
}

.select-all-button {
  background: #1d72b8;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.select-all-button:hover {
  background: #155a8a; /* Darker shade for hover */
}

.save-job-button {
  display: flex;
  width: 82px;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: linear-gradient(216deg, #16c2fc -33.54%, #7d3fff 104.65%);
  box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.07);
  border: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.save-job-button img {
  width: 16px; /* Adjust size as needed */
  height: 16px;
}

.cancel-button {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #e34f2a; /* Light gray background for cancel button */
  box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.07);
  border: none;
  cursor: pointer;
  color: #ffffff; /* Dark gray for the text */
  font-weight: bold;
}

.cancel-button img {
  width: 16px; /* Adjust size as needed */
  height: 16px;
}

.input-field.error {
  border-color: #e74c3c; /* Highlight input with an error */
}

.input-wrapper {
  position: relative;
  margin-bottom: 16px; /* Space between inputs */
}

.error-message {
  position: absolute;
  top: -24px; /* Adjust as needed to position above the input */
  left: 0;
  background-color: #f8d7da;
  color: #721c24;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap; /* Prevent long error messages from wrapping */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.vertical-line,
.horizontal-line {
  position: absolute;
  background-color: white;
  border: 2px solid white;
}

.vertical-line {
  width: 0;
  height: 11.67px;
  left: 10px;
  top: 4.17px;
}

.horizontal-line {
  height: 0;
  width: 11.67px;
  left: 4.17px;
  top: 10px;
}

.add-job-text {
  word-wrap: break-word;
}

.top-actions select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* Modal overlay styling */
.resume-upload-overlay {
  position: fixed; /* Ensures the overlay covers the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* High z-index to overlay other elements */
}
/* Modal content styling */
.resume-upload-modal {
  background: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Title styling */
.modal-title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

/* Dropzone styling */
.dropzone {
  border: 2px dashed #4981fd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropzone p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  color: #4981fd;
}

.dropzone.active {
  background-color: #f0f8ff;
}

/* Selected file text styling */
.selected-file {
  margin-top: 10px;
  font-size: 14px;
  color: #4981fd;
  font-weight: 500;
}

/* Success message styling */
.success-message {
  color: #4caf50;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}

/* Button styling */

.upload-button {
  background-color: #4981fd;
  color: #fff;
  margin-top: 10px;
}

.cancel-button {
  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #e34f2a; /* Light gray background for cancel button */
  box-shadow: 0px 1px 2px 0px rgba(17, 24, 39, 0.07);
  border: none;
  cursor: pointer;
  color: #ffffff; /* Dark gray for the text */
  font-weight: bold;
}

/* Spinner styles */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Outer circle, semi-transparent */
  border-top: 4px solid #3498db; /* Spinning part color */
  border-radius: 50%;
  width: 40px; /* Spinner size */
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Contact Form Styles */
.input-wrapper textarea.input-field {
  font-family: "Plus Jakarta Sans", sans-serif;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.input-wrapper textarea.input-field:focus {
  border-color: #4981fd;
  outline: none;
  box-shadow: 0 0 0 3px rgba(73, 129, 253, 0.1);
}

.input-wrapper input.input-field {
  height: 48px;
  padding: 0 12px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans", sans-serif;
  transition: border-color 0.3s ease;
}

.input-wrapper input.input-field:focus {
  border-color: #4981fd;
  outline: none;
  box-shadow: 0 0 0 3px rgba(73, 129, 253, 0.1);
}

.thank-you-message {
  background: linear-gradient(247deg, #16c2fc 0%, #7d3fff 100%);
  color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.character-count {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.input-wrapper {
  margin-bottom: 24px;
}

.input-field {
  padding: 12px 16px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #7d3fff;
  outline: none;
}

/* Email Verification Page Styles */
.verification-message h2 {
  font-size: 28px;
  color: #1d3557;
  margin-bottom: 20px;
}

.verification-message p {
  font-size: 16px;
  color: #333;
}

.verification-message .loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #457b9d; /* Match your primary color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
