/* src/AdminDashboard.css */

/* General Styles */
.admin-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.panel {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.panel h3 {
  margin-bottom: 15px;
  color: #333;
}

/* Error Message */
.error-message {
  color: red;
  font-weight: bold;
  padding: 10px;
}

/* User Table Container */
.user-table-container {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

/* Table Styles */
.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.user-table th {
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 1;
  /* Optional: Add a box-shadow to headers */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-table-container {
    max-height: 300px;
  }

  .user-table th,
  .user-table td {
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .user-table-container {
    max-height: 200px;
  }

  .user-table th,
  .user-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
}

/* Transactions Table Container */
.transactions-table-container {
  max-height: 500px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

/* Table Styles */
.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #f0f0f0;
}

.transactions-table th {
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 1;
  /* Optional: Add a box-shadow to headers */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.transactions-table tr:hover {
  background-color: #f1f1f1;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pagination-controls button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-controls span {
  margin: 0 10px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transactions-table-container {
    max-height: 400px;
  }

  .transactions-table th,
  .transactions-table td {
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .transactions-table-container {
    max-height: 300px;
  }

  .transactions-table th,
  .transactions-table td {
    padding: 8px 10px;
    font-size: 14px;
  }
}

.transaction-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.transactions-table-container {
  margin-top: 15px;
}

/* Tab Navigation Styles */
.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.tab-button.active,
.tab-button:hover {
  background-color: #007bff;
  color: #fff;
}

.tab-content {
  margin-top: 10px;
}
