/* Color Palette: #8c29fe, #06daff, #000040 */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap");

body {
  background-color: #f7f9fc;
  font-family: "Plus Jakarta Sans", sans-serif; /* Apply the new font family */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Theme-specific Component Styles */
.home-container {
  transition: background-color 0.3s, color 0.3s;
}

.theme-toggle-button {
  background-color: #7c3aed;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  transition: background-color 0.3s;
}

.theme-toggle-button:hover {
  background-color: #f80282;
}

/* More component-specific styles can go here... */

/* Dark Theme Specific Adjustments */

/* --------------------------------- */
/* Home Page Specific Styles */
/* --------------------------------- */
/* Navbar Styles */
.navbar {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo img {
  height: 40px;
}

.navbar-menu {
  display: flex;
  gap: 20px;
}

.navbar-menu a {
  text-decoration: none;
  color: #000040;
  font-size: 1rem;
  transition: color 0.3s;
  padding: 10px 15px;
}

.navbar-menu a:hover {
  color: #4981fd;
  background: rgba(73, 129, 253, 0.1);
  border-radius: 8px;
  text-decoration: none;
}

#active-navbar-menu-item {
  background: rgba(73, 129, 253, 0.1);
  color: #4981fd;
  border-radius: 8px;
  text-decoration: none;
}

/* -- Profile Dropdown button --*/
.profile-dropdown-container {
  width: 175px;
  height: 48px;
  position: relative;
}

.profile-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border-radius: 100px;
  border: 1px solid #ebeaed;
  background-color: #fff;
  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  flex-shrink: 0;
  font-size: 16px;
  color: white;
}

.user-info {
  margin-left: 12px;
  flex-grow: 1;
}

.user-name {
  color: #2e2c34;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  width: 90px;
}

.user-id {
  color: #84818a;
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.dropdown-arrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  width: 150px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  color: #2e2c34;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
}

.dropdown-menu li:hover {
  background-color: #f2f2f2;
}

/* -- Credit Button --*/
.credit-button-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 210, 48, 0.1);
  border-radius: 100px;
  padding: 5px;
  border: 1px solid #ffc107;
  box-sizing: border-box;
}

.icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  margin-right: 10px;
}

.icon-circle {
  background: white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-frame {
  width: 26.28px;
  height: 25.15px;
  position: relative;
}

.star-icon {
  position: absolute;
  width: 100%;
  height: 100%;
}

.credit-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.credit-label {
  color: black;
  font-size: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.credit-value {
  width: 100%;
  color: #f59231;
  font-size: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

/* -- Notification Ring Icon -- */
.notification-icon-container {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.notification-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: #00a488;
  border-radius: 50%;
}

.notification-count {
  color: white;
  font-size: 10px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  line-height: 1;
  margin-right: 5px;
}

.navbar-buttons {
  display: flex;
  gap: 15px;
}

.navbar-button,
.cta-button {
  background: linear-gradient(90deg, #16c2fc, #7d3fff);
  background-size: 200% 100%; /* Extend background to allow transition */
  background-position: 0% 0%; /* Initial background position */
  color: #fff;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  padding: 15px 30px;
  text-decoration: none;
  transition: background-position 0.4s ease, color 0.4s ease; /* Smooth transition */
  display: inline-block;
  font-weight: 600;
}
.navbar-button.signup-button {
  background: linear-gradient(90deg, #16c2fc, #7d3fff);
  border-radius: 100px;
}

.navbar-button:hover,
.cta-button:hover {
  background: linear-gradient(270deg, #3d1f7c, #471ca2);
  cursor: pointer;
  text-decoration: none;
}

.navbar-button.signup-button:hover {
  background: linear-gradient(270deg, #3d1f7c, #471ca2);
}

.navbar-button.login-button {
  background: transparent;
  color: #4981fd;
}
.navbar-button.login-button:hover {
  background: rgba(73, 129, 253, 0.1);
  color: #4981fd;
}

/* Burger Menu */
.burger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.burger-bar {
  width: 25px;
  height: 3px;
  background-color: #000040;
  transition: background-color 0.3s;
}

.burger-menu:hover .burger-bar {
  background-color: #8c29fe;
}

.mobile-auth-buttons {
  display: inline;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .navbar-buttons {
    display: none;
  }

  .burger-menu {
    display: flex;
  }

  .navbar-menu.open .mobile-auth-buttons {
    display: block;
  }
}

/* Hero Section Styles */
.hero-section {
  background-color: white;
  color: hsl(240, 100%, 13%);
  width: 100vw; /* Full width */
  height: 89vh; /* Full height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  text-align: center;
  box-sizing: border-box;
  padding: 0 20px; /* Optional: Some padding for inner spacing */
}

.hero-content {
  max-width: 800px;
  margin-top: 8px;
  position: absolute;
  top: 5%;
}

.hero-content h1 {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 3.3rem;
}

.powered-by {
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
}

.highlight {
  position: relative;
  display: inline-block;
}

.animated-word {
  display: inline-block;
  transition: opacity 0.5s ease, filter 0.5s ease, transform 0.5s ease;
}

.fade-out {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-10px);
}

.fade-in {
  opacity: 1;
  filter: blur(0px);
  transform: translateY(0px);
}

.underline-svg {
  position: absolute;
  bottom: -10px;
  left: 0;
  fill: #4981fd;
  pointer-events: none;
  transition: width 0.3s ease;
}

.hero-content p {
  font-size: 1.4rem;
  margin-top: 20px;
  display: inline-block;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-section {
    max-height: 1024px;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .powered-by {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 70px; /* Position below the navbar */
    left: 0;
    width: 100%;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
  }

  .navbar-buttons {
    display: none; /* Hide buttons on mobile */
  }
}
/* -- Features Section -- */

/* Features Section Styles */
.features-section {
  background: #a1bdda;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
}

.feature-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin-bottom: 40px;
  width: 100%;
}

.feature-image {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Alternate feature layout */
.feature-item:nth-child(odd) {
  flex-direction: row-reverse; /* Image on the right for odd items */
}

.feature-image {
  position: relative;
  width: 300px; /* Set your desired width */
  height: 200px; /* Set your desired height */
  border-radius: 12px; /* Round corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for the container */
  background-color: white;
  overflow: hidden;
}

/* MacBook Window Bar Styling */
.mac-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Height of the window bar */
  background-color: #e0e0e0; /* Light gray bar to resemble a Mac window */
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* MacBook window control buttons */
.window-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.window-dot--red {
  background-color: #ff5f57;
}

.window-dot--yellow {
  background-color: #ffbd2e;
}

.window-dot--green {
  background-color: #28c840;
}

.feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  position: relative;
  z-index: 1; /* Ensure the image appears below the window controls */
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.cropped-image-1 {
  width: 100px; /* Adjust the width based on how you want the image to be cropped */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the div's area */
  position: absolute; /* Positioning the image to control the cropped area */
  top: 15px; /* Adjust to display the specific part of the image you want */
  left: -10px; /* Adjust to display the specific part of the image you want */
}

.feature-text {
  flex: 1;
  padding: 20px;
}

.feature-text h2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.feature-text p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Responsive Styles for Features Section */
@media (max-width: 768px) {
  .feature-item {
    flex-direction: column; /* Stack items vertically on small screens */
    text-align: center;
  }

  .feature-image,
  .feature-text {
    padding: 10px 0;
  }
}

/* Testimonials Section Styles */

.testimonials-section {
  padding: 60px 20px;
  background-color: #f7f9fc; /* Light background */
}

.testimonials-header {
  text-align: center;
  margin-bottom: 40px;
}

.testimonials-header h1 {
  font-size: 3.5rem;
  color: #000040;
}

.testimonials-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Testimonials Grid */
.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Responsive grid */
  gap: 20px;
}

/* Testimonial Box */
.testimonial-box {
  background-color: #fbfdfd; /* Transparent background */
  border-radius: 16px;
  padding: 20px;
  position: relative;
  color: #475467;
  backdrop-filter: blur(10px); /* Subtle blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-box:hover {
  transform: translateY(-10px); /* Hover effect */
}

.quote-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.5;
}

.testimonial-quote p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.testimonial-author {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.author-name {
  font-weight: bold;
  color: rgb(98, 98, 98);
}

.author-position {
  font-size: 0.9rem;
  color: rgb(98, 98, 98);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonials-header h1 {
    font-size: 2rem;
  }

  .testimonials-header p {
    font-size: 1rem;
  }

  .testimonial-quote p {
    font-size: 1rem;
  }

  .author-position {
    font-size: 0.8rem;
  }
}

/* -- Initial Signup Section -- */
.signup-section {
  /*background-color: #f7f9fc;*/
  background-color: #ffffff;
  padding: 80px 20px;
  text-align: center;
}
.signup-section h2 {
  color: #000040;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.signup-section p {
  font-size: 1.2rem;
  margin-bottom: 40px;
}
.signup-form-container {
  margin: 0 auto;
  max-width: 400px;
}
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.input-field {
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  padding: 12px;
  transition: border-color 0.3s;
  padding-left: 2rem;
}
.input-field:focus {
  border-color: #16c2fc;
}
.signup-button {
  background: linear-gradient(90deg, #16c2fc, #7d3fff);
  border-radius: 100px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 15px;
  transition: background-color 0.3s;
}
.signup-button:hover {
  background: linear-gradient(270deg, #3d1f7c, #471ca2);
}
.spinner {
  height: 40px;
  margin: 0 auto;
  position: relative;
  width: 40px;
}
.double-bounce1,
.double-bounce2 {
  animation: bounce 2s ease-in-out infinite;
  background-color: #16c2fc;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.double-bounce2 {
  animation-delay: -1s;
}
@keyframes bounce {
  0%,
  to {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  color: #000040;
}

.footer-logo {
  margin-bottom: 10px;
  height: 40px;
}

.footer-nav-links {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-link {
  margin: 0 15px;
  font-size: 14px;
  color: #000040;
  text-decoration: none;
}

.footer-link:hover {
  color: #000040;
  text-decoration: underline;
}

.footer-social-icons > a > i {
  font-size: 24px;
  margin-bottom: 10px;
  color: rgb(73, 129, 253);
}

.footer-social-icons > a {
  margin-left: 15px;
  color: #f1faee;
  text-decoration: none;
}

.footer-social-icons > a > i:hover {
  color: #1d5e73;
}

.footer-social-icons > a > i {
  font-size: 24px;
}

.footer-copyright {
  font-size: 12px;
}

.modal-overlay {
  align-items: center;
  background-color: #00000080;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  max-width: 400px;
  padding: 20px;
  text-align: center;
}
.modal-content h2 {
  margin-bottom: 10px;
}
.modal-content p {
  margin-bottom: 20px;
}
.modal-content button {
  background-color: #457b9d;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
}
.modal-content button:hover {
  background-color: #1d3557;
}

/* How It Works Section */
.how-it-works-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.how-it-works-section h2 {
  font-size: 2.5rem;
  color: #7c3aed;
  margin-bottom: 40px;
}

.steps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.step {
  max-width: 300px;
  padding: 20px;
}

.step h3 {
  font-size: 1.5rem;
  color: #f80282;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  color: #333;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .signup-form {
    margin: 0;
    max-width: 350px;
  }
}

/* FAQ Section */
.faq-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.faq-section h2 {
  font-size: 2.5rem;
  color: #000040;
  margin-bottom: 40px;
}

.accordion-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.accordion-item {
  border-bottom: 1px solid #c8c9c9;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-item.active .accordion-answer {
  max-height: 200px; /* Adjust as needed */
}

.accordion-question {
  background-color: #fff;
  padding: 20px;
  font-size: 1.2rem;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-question:hover {
  background-color: #f0f0f0;
}

.accordion-icon {
  font-size: 1.5rem;
  color: #7c3aed;
}

.accordion-answer {
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.accordion-answer p {
  padding: 0 20px 20px;
  font-size: 1rem;
  color: #555;
}

/* Tutorial Section */
.tutorial-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.tutorial-section h2 {
  font-size: 2rem;
  color: #7c3aed;
  margin-bottom: 40px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Marquee Section */
.marquee-section {
  padding: 60px 20px;
  background-color: #ffffff;
  text-align: center;
}

.marquee-section h2 {
  font-size: 2rem;
  color: #000040;
  margin-bottom: 40px;
}

.marquee-container {
  overflow: hidden;
  position: relative;
}

.marquee {
  display: flex;
  animation: marquee 20s linear infinite;
}

.marquee img {
  width: 150px;
  margin: 0 20px;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .marquee img {
    width: 100px;
    margin: 0 10px;
  }
}

/* Extra Tools Section */
.extra-tools-section {
  padding: 60px 20px;
  background-color: #f7f9fc; /* Light background color */
}

.extra-tools-header {
  text-align: center;
  margin-bottom: 40px;
}

.extra-tools-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000040;
}

.extra-tools-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Grid Layout for Tools */
.extra-tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-items: center;
}

/* Individual Tool Card */
.tool-card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #e0e0e0;
  text-align: center;
  padding: 20px;
  max-width: 300px;
}

.tool-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.tool-card h3 {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #000040;
  font-weight: bold;
}

.tool-card p {
  font-size: 1.1rem;
  margin-top: 10px;
  color: #555;
  padding-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .extra-tools-header h1 {
    font-size: 2rem;
  }

  .extra-tools-header p {
    font-size: 1rem;
  }

  .tool-card h3 {
    font-size: 1.5rem;
  }

  .tool-card p {
    font-size: 1rem;
  }
}

/* How AppliMatch Works Section */
.magic-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.magic-section h2 {
  font-size: 2.5rem;
  color: #7c3aed;
  margin-bottom: 40px;
}

.magic-steps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.magic-step {
  max-width: 180px;
  padding: 20px;
  text-align: center;
}

.magic-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #f80282;
}

.magic-step h3 {
  font-size: 1.4rem;
  color: #7c3aed;
  margin-bottom: 10px;
}

.magic-step p {
  font-size: 1rem;
  color: #333;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .magic-steps-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Signup Page Styling */
.signup-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fafafa;
  padding: 1rem;
}

.left-panel {
  flex: 1.5;
  background: #fff;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-panel {
  flex: 1;
  background: linear-gradient(216.1deg, #16c2fc, #7d3fff);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #fff;
}

.dashboard-mockup img {
  max-width: 60%;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Breadcrumb Styling */
.breadcrumb {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 2rem;
}

.breadcrumb-step {
  display: flex;
  align-items: center;
  gap: 8px;
}

.breadcrumb-step .step-label {
  font-weight: bold;
  color: #0e1b2f;
}

.breadcrumb-step.active .step-label {
  color: #0e1b2f;
}

.breadcrumb-arrow {
  font-size: 1.5rem;
  color: #0e1b2f;
}

/* Form Titles */
.signup-title {
  font-size: 2rem;
  font-weight: bold;
  color: #0e1b2f;
  text-align: left;
  width: 100%;
}

.signup-subtitle {
  color: #666;
  margin-bottom: 1.5rem;
  text-align: left;
  width: 100%;
}

/* Signup Form Styling */
.signup-form {
  width: 100%;
  max-width: 500px;
}

.input-row {
  display: flex;
  gap: 1rem;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
}

.input-group input:focus,
.input-group select:focus {
  border-color: #4981fd;
  outline: none;
}

/* Styles for Job Interests */
.job-interests {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.job-interest-tag {
  background-color: #4981fd;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
}

.job-interest-tag:hover {
  background-color: #3569e6;
}

/* Signup Button */
.signup-button {
  width: 100%;
  background: linear-gradient(90deg, #16c2fc, #7d3fff);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
}

.signup-button:hover {
  background: linear-gradient(90deg, #7d3fff, #16c2fc);
}

/* Experience Section */
.experience-section {
  width: 100%;
  max-width: 500px;
}

.experience-section .signup-title {
  margin-bottom: 1rem;
}

.experience-section p {
  font-size: 1rem;
  color: #333;
}

.experience-options {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.radio-button {
  padding: 0.75rem 1.5rem;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
}

.radio-button:hover {
  background-color: #eaeaea;
}

/* Published Paper Section */
.published-paper-section {
  width: 100%;
  max-width: 500px;
}

.published-paper-section .input-group {
  margin-bottom: 1rem;
}

.published-paper-section .navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.back-button,
.continue-button {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #4981fd;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.back-button:hover,
.continue-button:hover {
  background-color: #3569e6;
}

/* General Input Focus */
input:focus,
select:focus,
textarea:focus {
  outline: none;
  border-color: #4981fd;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .signup-page {
    flex-direction: column;
    padding: 0;
  }

  .left-panel,
  .right-panel {
    flex: none;
    width: 100%;
    transform: none;
    padding: 1rem;
  }

  .dashboard-mockup img {
    max-width: 100%;
  }
}
.interest-tag {
  background-color: #4981fd;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
  position: relative;
  top: 10px;
}

.interest-tag:hover {
  background-color: #3569e6;
}

/* ... Existing styles ... */

/* Message Styling */
.message {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 8px;
}

.message.error {
  background-color: #fdecea;
  color: #d32f2f;
}

.message.success {
  background-color: #e7f5e6;
  color: #388e3c;
}

/* Selected Radio Button */
.radio-button.selected {
  background-color: #4981fd;
  color: white;
}

/* Experience Section Textarea */
.experience-section textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  resize: vertical;
}

.experience-section textarea:focus {
  border-color: #4981fd;
  outline: none;
}

/* Job Interests Tags */
.job-interest-tag {
  background-color: #4981fd;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
}

.job-interest-tag:hover {
  background-color: #3569e6;
}

/* Interests Tags */
.interest-tag {
  background-color: #4981fd;
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.9rem;
}

.interest-tag:hover {
  background-color: #3569e6;
}

.left-panel {
  position: relative;
  overflow-y: auto; /* Add scrolling if content overflows */
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
}

.breadcrumb-step {
  color: #aaa;
}

.breadcrumb-step.active {
  font-weight: bold;
  color: #4981fd;
}

.breadcrumb-arrow {
  color: #aaa;
}

/* Signup Page Styling */
.signup-page {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  min-height: 100vh;
  background-color: #fafafa;
}

.left-panel {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  background: #fff;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.right-panel {
  flex: 1;
  min-width: 300px;
  background: linear-gradient(216.1deg, #16c2fc, #7d3fff);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: #fff;
}

.dashboard-mockup img {
  max-width: 100%;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .signup-page {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    width: 100%;
    padding: 1rem;
  }

  .right-panel {
    display: none; /* Hide the right panel on small screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .left-panel,
  .right-panel {
    padding: 1.5rem;
  }
}

.input-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 600px) {
  .input-row {
    flex-direction: row;
  }
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.signup-button,
.back-button,
.continue-button {
  width: 100%;
  text-align: center;
}

/* Add this to your CSS file */
.custom-loading-spinner {
  width: 150px;
  height: 150px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: #4981fd;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Overlay Styles */
.custom-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-stage {
  margin-top: 20px;
  color: #fff;
  font-size: 1.2em;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 2rem;
}

.breadcrumb-step {
  display: flex;
  align-items: center;
  gap: 8px;
}

.breadcrumb-step .ellipse {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
  flex-shrink: 0;
}

.breadcrumb-step .step-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a9abaf;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.breadcrumb-step .step-label {
  font-family: Plus Jakarta Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #a9abaf;
}

.breadcrumb-step.active .ellipse {
  background-color: #4981fd;
}

.breadcrumb-step.active .step-number {
  color: #ffffff;
}

.breadcrumb-step.active .step-label {
  color: #0e1b2f;
  font-weight: 600;
}

.breadcrumb-step.completed .ellipse {
  background-color: #4981fd;
}

.breadcrumb-step.completed .step-number {
  color: #ffffff;
}

.breadcrumb-step.completed .step-label {
  color: #0e1b2f;
  font-weight: 600;
}

.breadcrumb-arrow {
  display: flex;
  align-items: center;
}

.validation-message {
  color: #d32f2f;
  font-size: 0.9rem;
}

.tooltip {
  position: relative;
  cursor: pointer;
  margin-left: 5px;
}

.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -5px;
  left: 25px;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  display: none;
}

.tooltip:hover::after {
  display: block;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: #f8f8f8; /* Off-white background */
  position: relative;
}

.login-container {
  width: 400px;
  margin: auto;
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 2; /* Ensure the form is above the artistic shape */
}

.input-container {
  position: relative;
  margin-bottom: 1rem;
}

.login-input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.login-input-field.with-icon {
  padding-left: 35px;
}

.login-button {
  width: 100%; /* Make the button full width */
  padding: 0.75rem;
  background: #1d3557;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.login-button:hover {
  background: #457b9d; /* Change color on hover */
}

.signup-error-message {
  color: red;
  font-weight: bold;
  padding: 20px;
}

.artistic-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* 30% of the page height */
  background: linear-gradient(135deg, #e63946, #a8dadc, #457b9d);
  z-index: 0; /* Ensure the artistic shape is behind the form */
}

/* Purchase Credits styling */
.panel.selected {
  border: 2px solid #7d3fff; /* Highlight selected package */
}

.panel:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(73, 129, 253, 0.4);
}

@media (max-width: 768px) {
  .login-container {
    padding: 2rem;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 1rem;
    max-width: 80%;
  }
}

.floating-figure {
  position: absolute;
  max-width: 250px; /* Consistent size */
  opacity: 0.8; /* Less distracting */
  z-index: 0; /* Ensure it doesn't overlap content */
}

.progress-tracker {
  top: 10%; /* Adjusted for alignment */
  left: 3%; /* Ensure placement inside bounds */
}

.stats-panels {
  top: 10%; /* Adjust alignment with .progress-tracker */
  right: 5%; /* Ensure symmetrical positioning */
}

.bulk-tailor-buttons {
  bottom: 15%; /* Keep consistent alignment */
  left: 50%;
  transform: translateX(-50%);
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.floating-figure {
  animation: float 5s ease-in-out infinite; /* Smoother animation */
}

@media (max-width: 968px) {
  .progress-tracker,
  .stats-panels,
  .jobs-table,
  .bulk-tailor-buttons {
    display: none; /* Match both editions */
  }
}

.hero-section {
  position: relative;
  overflow: hidden; /* Prevent elements from spilling */
  padding: 20px;
}

.home-jobs-table {
  animation: none;
  border: 1px solid #0000001a;
  border-radius: 10px;
  box-shadow: 0 4px 12px #0000001a;
  left: 50%;
  max-width: 52%;
  opacity: 1;
  position: absolute;
  bottom: -15%;
  transform: translateX(-50%);
  z-index: 1;
}

/* Prevent horizontal scrolling */
body,
html {
  overflow-x: hidden; /* Disables horizontal scrolling */
}

/* For the homepage container */
.homepage-container {
  overflow-x: hidden; /* Ensure no horizontal scroll */
  position: relative; /* Keeps child elements positioned correctly */
}

/* For sections spilling over */
.hero-section,
.features-section,
.signup-section,
.testimonials-section {
  max-width: 100vw; /* Ensure elements don't exceed the viewport width */
  overflow-x: hidden; /* Prevent any overflow */
  position: relative; /* Maintain relative positioning */
}

/* Privacy Policy Styling */
.privacy-policy {
  padding: 2rem 1rem;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

section {
  margin-bottom: 2rem;
}

section h2 {
  font-size: 1.5rem;
  color: #2980b9;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  margin: 1rem 0;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Refund Policy Styling */

.refund-policy-container {
  padding: 20px;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.refund-policy-container h1,
.refund-policy-container h2 {
  color: #000040;
}

.refund-policy-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.refund-policy-container a {
  color: #4981fd;
  text-decoration: none;
}

.refund-policy-container a:hover {
  text-decoration: underline;
}

footer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
  color: #555;
}

/* Terms of Service Styling */
.terms-of-service-container {
  padding: 20px;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.terms-of-service-container h1,
.terms-of-service-container h2,
.terms-of-service-container h3 {
  color: #000040;
}

.terms-of-service-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

footer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
  color: #555;
}

.navbar-button.dashboard-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.navbar-button.dashboard-button:hover {
  background-color: #45a049;
}

.hidden {
  display: none;
}

.logo-container {
  position: relative;
  display: inline-block;
}

.logo-image {
  display: block;
  max-height: 40px; /* Adjust size as needed */
}

.beta-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #4981fd; /* Red background */
  color: #fff; /* White text */
  font-size: 10px; /* Adjust size as needed */
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
  transform: translate(50%, -50%);
}
